.marker {
  background-image: url("../images/MapMarkers/mapbox-marker-icon-gray.svg");
  background-size: 100%;
  background-repeat: no-repeat;
  height: 2.1rem;
  width: 1.3rem;
  z-index: 1;
}

/* .marker:hover {
  height: 3rem;
  width: 3rem;
} */

.red-marker {
  background-image: url("../images/MapMarkers/mapbox-marker-icon-red.svg");
  height: 2.7rem;
  width: 1.7rem;
  z-index: 2;
}
.mapboxgl-ctrl-top-right{
  top:6.5rem !important;
}