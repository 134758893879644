
.glossary-fullscreen {
  /* width: 100%;
  height: 100%; */
  /* overflow-y: hidden; */
  display: flex;
  flex-direction: column;
  height: 100%;
}

.glossary-header {
  width: 100%;
}

.glossary-button-container {
  display: flex;
  flex-direction: row-reverse;
}

.glossary-cancel {
  margin: 10px;
}

.glossary-header h1 {
  margin: 0.5rem 1rem !important;
  font-size: 2rem;
  font-weight: 500;
}

.glossary-body {
  display: flex;
  overflow: auto;
  flex-direction: row;
  align-items: stretch;
  /* width: fit-content; */
  /* position: absolute; */
  /* z-index: 100000; */
}
.glossary-body::-webkit-scrollbar {
  width: 0;
}

.glossary-letters {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 1rem 0.2rem;
  height: 100%;
  width: fit-content;
}

.glossary-entry-def {
  overflow-y: scroll;
  /* width: 60em; */
  flex:1;
  height: 100%;
  padding: 1rem 0.6rem;

}

@media only screen and (min-height: 1000px) {
  .glossary-letters {
    height: 52vh;
  }
  .glossary-entry-def {
    height: 52vh;
  }
}

@media only screen and (min-height: 2000px) {
  .glossary-letters {
    height: 65vh;
  }
  .glossary-entry-def {
    height: 65vh;
  }
}

.glossary-letter {
  /* margin-bottom: 10px; */
  text-decoration: underline;
  cursor: pointer;
  width: fit-content;
  padding: 0.2rem;
}

.glossary-index {
  display: flex;
  flex-direction: column;
  gap:0.5rem;
 /* font-size: 0.8rem; */
  /* text-decoration: underline; */
}

.glossary-entry {
  margin-left: 20px;
  margin-right: 20px;
}

.glossary-entry-title {
  font-size: 2em;
  margin-bottom: 10px;
}

.glossary-entry-text {
  text-decoration: none;
}

.glossary-word {
  text-decoration: underline;
}

.mapbox-logo {
  display: none;
}

.mapboxgl-ctrl-logo {
  display: none !important;
}

.mapbox-improve-map {
  display: none;
}

.mapbox-logo {
  display: none;
}
/* .mapboxgl-ctrl-logo { display: none !important; }  */
.mapbox-improve-map {
  display: none;
}
.mapboxgl-ctrl-compass {
  display: none;
}
