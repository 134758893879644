/*Resets*/
*{
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
a{
  text-decoration: none !important;
  color:var(--black) !important;
}
ul{
  padding: inherit;
  padding-top: 0;
  padding-bottom: 0;
}
html, body{
  position: relative;
  height:100%;
  width: 100%;

}
#root{
  height: 100%;
}
/*Variables*/
:root{
  /*color*/
  --white: #F9F9F9;
  --black: #2E2E2E;
  --medium-grey:#868686;
  --light-grey: #eaeaea;
   /*translucent background*/
  --grey-opacity65: rgba(241, 241, 241, 0.65);
  --white-opacity80:rgba(249, 249, 249, 0.8);
  /*padding*/
  --basic-padding: 0 1.88rem;
  --text-padding: 1.2rem;
  /*shadows*/
  --text-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.06);
  --btn-shadow-primary: 0px 4px 2px rgba(137, 137, 137, 0.5), 0px 2px 2px rgba(137, 137, 137, 0.25);
  --btn-shadow-secondary: 0px 2px 4px rgba(0, 0, 0, 0.2), 0px 2px 2px rgba(0, 0, 0, 0.06);
  --btn-shadow-active: inset 0px 4px 2px rgba(137, 137, 137, 0.5), inset 0px 2px 2px rgba(137, 137, 137, 0.25);
  --divider-top-shadow: 0px -1px 1px rgba(137, 137, 137, 0.15);
  --divider-btm-shadow: 0px 1px 1px rgba(137, 137, 137, 0.15);
  --divider-right-shadow: 1px 0px 1px rgba(137, 137, 137, 0.15);
 
  /* --backgound-80: rgba(241, 241, 241, 0.8); */
  /*screen sizes*/
  --breack-point: 768px;
  --max-width:1920px;
}

/*Utilities*/

.logo-wrapper.left{
  border-right: var(--medium-grey) 0.5px solid;
}
.field-wrapper-row.flex-align-start{
  align-items: flex-start;
}
/*General Styles*/
html{
  font-family: Arial, Helvetica, sans-serif;

}

body{
  /* height:100vh; */
  color:var(--black);
  position:relative; 
  min-height:100% !important;
  /* overflow-y: hidden !important; */
  background-color: var(--white) !important;
}
h1{
  font-size: 2.75rem;
  margin-bottom:0 !important;
}
h2{
  font-size: 2rem;
  margin-bottom:0 !important;
}
h3{
  font-size: 1.63rem;
  margin-bottom:0 !important;
}
h4{
  font-size: 1.25rem;
  margin-bottom:0 !important;
}
h5{
  font-size: 1.13rem;
  margin-bottom:0 !important;
}
h6{
  font-size: 1.13rem;
  font-weight: 400;
  margin-bottom:0 !important;
}
p{
  font-size: 1rem;
  margin-bottom:0 !important;
}
.p-detail{
  font-size: 0.88rem;
}
.text-ss{
  font-size: 0.78rem;
}
.text-ssx{
  font-size: 0.6rem;
}
.link-underbar{
  text-decoration: underline !important;
  overflow-wrap: break-word;
  word-break: break-all;
}
.caption-text{
  font-size: 0.65em;
  padding:0 0.3rem;
}
/*blocks*/
/*logo*/
.logo{
  height:100%
}
.logo-wrapper{
  display: inline-block;
  padding: 0.47rem 1rem;
  height: 100%;
  text-align: center;
}
.logos-wrapper{
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  
}
.name-wrapper{
  height: fit-content;
  padding: 0.47rem 0rem;
}
.name-wrapper>a{
  font-size: 100%;
  
}
@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
} 
.loader {
  border: 10px solid grey;
  border-top: 10px solid rgb(0, 0, 0);
  border-radius: 50%;
  width: 50px;
  height: 50px;
  animation: spin 1s linear infinite;
  margin: 10rem auto 0 auto;
}

.logo-name{
text-decoration: none;
color: var(--black);

font-size:67% !important;
font-weight: bold ;
}
.logo-name:hover{
  cursor: pointer;
  color: var(--black);
}
/*button*/
.btn-primary{
  font-size: 1.25rem;
  font-weight: 700;
  padding: 0.5em 0.4em;
  background-color: var(--white);
  border: none;
  border-radius: 0.3rem;
  box-shadow: var(--btn-shadow-primary);
}
.btn-secondary{
  width: fit-content;
  font-size: 1rem;
  padding: 0.4em 0.4em;
  background-color: var(--white);
  border: none;
  border-radius: 0.3rem;
  box-shadow: var(--btn-shadow-secondary);
}

.slight-padding{
  padding:0.1rem;
}
.btn-primary:active{
  box-shadow: var(--btn-shadow-active);
}
.btn-black{
  color:var(--white);
  background-color: var(--black);
}
/*swiper carousel*/
.swiper-slide{
  aspect-ratio:4/3;
}

.swiper-slide img{
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center center;
}
.caption-text{
  position: absolute;
  bottom:0;
  left: 0;
  width: 100%;
  display: block;
  background-color: var(--white);
  z-index: 500;
}
.swiper-pagination{
  position: absolute;
  top: 10px !important;
  bottom:inherit !important;
}
/*top logos*/
.container-top{
  position:fixed;
  width: 100%;
  max-width: var(--max-width);
  top: 0;
  height: 4rem;
  display:flex;
  /* padding-top: 36px; */
  background-color: var(--white);
  box-shadow: var(--divider-btm-shadow);
  z-index: 100;
}
.logos-container.top{
  width: 100%;
  justify-content: space-between;
}
/*filter*/
.list-style{
  background-color: transparent !important;
  color: var(--white);
  border-radius: 0.3rem;
  border: var(--white) 1px solid ;
  margin-right:0.5em;
  padding: 0.01em;
  /*font*/
  font-size: 2.75rem;
  font-weight: 700;
  text-shadow: var(--text-shadow);
  -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    padding: 0 0.3rem;
}
/* .list-style>option{
  text-indent: 0.2rem;
} */
.list-style.sm{
  color:var(--black);
  border: var(--black) 1px solid;
  font-size: 1.63rem;
  text-shadow: none;
  margin-right: 0;
  padding: 0.1em 0.1em;

  width: fit-content;
}
.list-style.sm.typology-list-style{
   width: 100%;
  overflow:hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
/*navbar-bottom*/
.nav-container{
  display: flex;
  flex-direction: row;
  /* flex-wrap: wrap; */
  align-items: center;
  justify-content: space-between;
  width: 100%;
  height: 3.75rem;
  bottom: 0;
  position: fixed;
  /* padding: 0.35em 0em 0.4em 0em; */
  background-color: var(--white);
  z-index: 10;
  color:var(--black);
  box-shadow: var(--divider-top-shadow);
}
.nav-links{
  display: flex;
  flex-direction: column;
  align-items: center;
  color:var(--black);
  width: calc(100%/4);
  cursor: pointer;
}
.nav-links svg{
  font-size: 1.2rem;
}
.container{
  padding-top: 4rem;
  height: 100%;
}
.content-container{
  /* height: calc(100% - 3.75rem - 4rem); */
  height: 100%;
  background-color: var(--white);
  max-width: var(--max-width);
  margin: auto;
  padding-bottom: 3.74rem;
  position: relative;
  display: flex;
  flex-direction: column;
}
/*table*/
table{
  border-collapse:separate !important;
  border-spacing: 0;
  border-radius: 1rem;
  font-size: 0.8rem;

}
table th{
text-align: center;
}
table tr:first-child th:first-child {
  border-top-left-radius: 0.5rem;
}
table tr:first-child th:last-child {
  border-top-right-radius:  0.5rem;
}
table tr:last-child td:first-child {
  border-bottom-left-radius:  0.5rem;
}
table tr:last-child td:last-child {
  border-bottom-right-radius:  0.5rem;
}
table td, table th{
  padding: 0.5rem;
}
table thead th{
  background-color: var(--black);
  border: 0.3px solid #d5d5d5;
  font-weight: 500;
  color:var(--white);
 
}
table tbody td{
  border: 0.3px solid #d5d5d5;
}

table tbody tr:nth-child(odd){
  background-color: var(--light-grey);
}

@media screen and (min-width: 768px){
  html{
    font-size: 21.8px;
  }
  .nav-container{/*web mode navbar*/
    flex-direction: column;
    flex-wrap: nowrap;
    height: 100%;
    width: 4rem;
    justify-content: flex-start;
    padding-top: 5rem;
    box-shadow: var(--divider-right-shadow);
  }
  .nav-links{
    padding: 1rem;
    font-size: 0.6rem;
  }
  .container-top{
    height: 4rem;
    padding-top: 0;
  }
  .container{
    /* margin-top: 4rem; */
  }
  .content-container {
    padding-bottom: 0;
    padding-left:4rem;
    /* height: calc(100% - 4rem); */
  }
  .logo-name{
    text-decoration: none;
    color: var(--black);
    
    font-size:100% !important;
    font-weight: bold ;
    }
}


/* Gateway page */
.home-container{
  position:relative;
  max-width: var(--max-width);
  margin: auto;
  color:var(--white);
  z-index: 101;
  display: flex;
  flex-direction: column;
  overflow-y: hidden;
  overflow-x: hidden;
  /* height: calc(100% - 4.25rem); */
  height:100%;

}
.home-main{
  /* height: calc(100% - 4.25rem); */
  height: 100%;
  flex:1;
  padding: var(--basic-padding);
  padding-bottom:4.25rem;
  text-shadow: var(--text-shadow);
  /*background image*/
  position: relative;
  z-index: 1;
  display: flex;
  justify-content: center;
  flex-direction: column;

}
/*background filter*/
/* .home-main>img{
  position:absolute;
  left:-1rem;
  width: calc(100% + 1rem);
  
} */
.home-main::before{
  position: absolute;
  top:0;
  left:0;
  content: "";
  /* background: center no-repeat; */
  background:linear-gradient(65deg, rgba(82, 95, 106, 0.55) 70%, transparent 70%);
  background-attachment:inherit;
  width: 100%;
  height: 100%;
  z-index: -1;
}
.home-content{
  /* height: 100vh; */
  /*scroll bar no display*/ 
  /* overflow-y: scroll;
  overflow-x: hidden;
  -ms-overflow-style: none;
  scrollbar-width: none; */
  height: fit-content;
  padding-top:3rem;
 
}
.home-content::-webkit-scrollbar {
  display: none;
}
.home-title{
  /* padding-top:20vh; */
}
.list-spacer{
  margin-top: 1.5em ;
  text-align: left;
}

.home-questionmark{
  display: inline;
}

/*button*/
.button-spacer{
  margin-top: 4rem;
  display: flex;
  align-items: center;
  justify-content: center;
}

/*logo-bottom section*/
.container-btm{
 position: fixed;
  width: 100%;
  max-width: var(--max-width);
  bottom: 0;
  height: 4.25rem;
  display:flex;
  justify-content: center;
  background-color: var(--white);
  box-shadow: var(--divider-top-shadow);
  z-index: 500;
}
.logos-container{
  background-color: var(--white);
  display: flex;
  flex-direction: row;
 padding:0.5rem;
  /* width: fit-content; */
  flex:1;
}
.main-logos-wrapper{
  width: fit-content;
  display: flex;
  flex-direction: row;
  height: 100%;
  align-items: center;
  margin:auto;
}
@media screen and (min-width: 768px){
 
  .home-main{
    /* height: calc(100% - 3.6rem); */
    padding-bottom: 3.6rem;
  }
  .home-main::before{
    background:linear-gradient(65deg, rgba(82, 95, 106, 0.55) 60%, transparent 60%);
  }
  .home-title{
    padding-top:3.5rem;
  }
  .home-content{
    padding-top:0;
  }
  .button-spacer{
    margin-top: 4rem;
    width: 90%;
  }
  .container-btm{
    height: 3.6rem;
  }

}

/*map page*/
/* .map-container{
  height:calc(100% - 3.75rem - 6rem);
  padding-bottom: 0;
  
} */
.header-container{
  width: 100%;
  /* position: relative; */
  background-color: var(--grey-opacity65);
  z-index: 100;
  display: flex;
  flex-direction: row;
  padding: 1em;
  align-items: center;
  gap:0.6rem;
}
.map-classification-header{
  flex-wrap: wrap;
}
.map-classification-header .list-style.sm{
  font-size: 1.25rem;
}
.map-clickable{
  flex-grow: 1;
}
.map-carousel-container{
  /* position: fixed; */
  /* bottom: 3.75rem; */
  width: 100%;
  padding: 1rem 1rem;
  z-index: 100;
  background-color: var(--grey-opacity65);
}
.map.lg{
  width: 100%;
  height: 100%;
  position: absolute !important;
  top:0;
  left:0;
}
@media screen and (min-width: 768px){
  /* .map-container{
    height: calc(100% - 4rem);
  } */
  .map-carousel-container{
    bottom: 0;
  }
  .map.lg{
    width: calc(100% - 4rem);
    left:4rem;
  }
}
@media screen and (max-width:357px){
  .list-style.sm{
    width: 100%;
  }
}

/*project page*/
.project-container{
  overflow-y: scroll;
}
.project-main{
  display: flex;
  flex-direction: column;
  box-shadow: var(--divider-btm-shadow);
}
.text-span-wrapper{
  /*span link */
  display: inline;
  
}
.reference-padding{
  padding-top: 0.5rem ;
}
/*project-carousel*/
.project-swiper{
  max-width: 800px;
  width: 100%;
  aspect-ratio: 4/3;
}
.slider-arrow{
  color: var(--white) !important;
}
.swiper-pagination{
  padding-bottom: 0.5rem;
}
.swiper-pagination-bullet {
  background-color: var(--white) !important;
}
.captions-wrapper{
  padding: 0 0.5rem
}
/*title*/
.project-title{
  padding: var(--text-padding);
  padding-top:0.5rem ;
  display: flex;
  flex-direction: column;
  gap:0.3rem;
}
.project-title-line-wrapper{
  display: flex;
  flex-direction: row;
  gap: 0.5rem;
  flex-wrap:wrap;
}

/*text-box*/
.text-container{
  padding: var(--text-padding);
  box-shadow: var(--divider-btm-shadow);
}
.text-container.with-title{
  display: flex;
  flex-direction: column;
  gap:var(--text-padding)
}
.text-wrapper.with-title{
  display: flex;
  flex-direction: column;
  gap:calc(var(--text-padding))
}
.p-wrapper.with-title{
  display: flex;
  flex-direction: column;
  gap:calc(var(--text-padding)/2)
}
.project-description{
  display: flex;
  flex-direction: column;
  gap:calc(var(--text-padding)/2)
}
/*address section in project page*/
.project-description-map-wrapper{
  display: flex;
  flex-direction: column;  
}
.project-map{
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-content: flex-start;
  gap:1rem;
}

.map.sm{
  height: 7rem;
  aspect-ratio:3/2;
}
.map-address{
  flex: 1 1;
}
/*navigation buttons in project page*/
.project-buttons-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:0.5em;
}
.project-btn-contents-wrapper{
  display: flex;
  align-items: center;
  flex-direction: row;
  gap:0.5em;
}
.project-classification-wrapper{
  /* padding: 0 1em; */
  gap:1em !important;
}
.project-btn-text{
  text-align: start;
}
.otherresource-wrapper{
  /* display: flex; */
  flex-direction: row;
  flex-wrap:wrap;
}
/*related project carousel*/
.slider-title-wrapper{
  
  width: 80%;
  margin:0 auto;
  overflow:hidden;
  text-overflow: ellipsis;
}
.slider-title{
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;

  text-align: center;
}
.related-project-swiper{
  margin-top: 1rem;
  max-width: 1200px;
}
.related-project-swiper-slide{
  background-position: center;
  background-size: cover;
  pointer-events: none;
  width: 13rem !important;
  position: relative;
} 
.slider-controller{
    visibility: hidden;
}

.swiper-slide-active{
   pointer-events:all; /*to active link only in active image*/
}

.related-project-swiper-slide img {
  
  border-radius: 0.5rem;
} 
.other-resources-link{
  text-decoration: underline !important;
}
@media screen and (min-width: 768px){
  /* .project-container{
    padding-bottom: 0;
  } */
  .slider-controller{
    visibility: visible;
  }
  .related-project-swiper-slide{
    width: 15rem !important;
  } 
  .project-description-map-wrapper{
    flex-direction: row;
  }
  .project-description{
    box-shadow: var(--divider-right-shadow), var(--divider-btm-shadow);
    flex:1 1;
  }
  .project-map{
    flex-wrap: wrap;
    max-width: 30%;
  }
  .map.sm{
    width: 100%;
    max-width: 10rem;
  }
  .map-address{
    min-width: 4rem;
    white-space: wrap;
  }
}
/*Explore page*/
.explore-container{
  padding: var(--text-padding);
  gap: 1rem;
  overflow-y: scroll;
}

.explore-cards-wrapper{
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-top:1rem;
}
.classification-card{
  display: flex;
  flex-direction: column;
  padding: 1em;
  justify-content: center;
  align-items: flex-start;
  box-shadow: var(--btn-shadow-secondary);
  border-radius: 0.5rem;
  gap:1rem;
  background: var(--white-opacity80);
}
.card-title-button-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 1em;
  width: 100%;
  justify-content: space-between;
}
.card-btn{
  display: flex;
  border-radius: 30rem;
  background-color: var(--light-grey);
  padding: 0.6rem;
  cursor: pointer;
}
.classificaiton-text-read-more-wrapper{
  display: flex;
  flex-direction: column;
  gap: 0.5em;
  width: 100%;
}
.classification-text{
  overflow:hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  text-overflow: ellipsis;
  line-height: 1.5rem;
  max-height: 3rem;
}
.classification-text.expanded{
  overflow: visible;
  display: inline;
  max-height: fit-content;
}
.classification-description{
  
}
.toggle-wrapper{
  display: flex;
  flex-direction: row;
  color: var(--medium-grey);
  cursor: pointer;
  gap:0.3em;
}
.read-more, .show-less{
  margin-left: auto;
}
@media screen and (min-width:768px) {
  .explore-container{
    padding: var(--text-padding);
    /* padding-left: 5rem; */
  }
}
/*Glossary page*/
.ReactModal__Overlay{
  z-index: 900;
}
.ReactModal__Content{
  background-color: var(--white) !important;

}
.glossary-body{
  box-shadow: var(--divider-top-shadow);
}
.glossary-word:hover{
  cursor: pointer;
}
.glossary-cancel:hover{
  cursor: pointer;
}
/*form*/
.form-container h5{
  font-size: 0.9rem;
}
.form-container h6{
  font-size: 0.9rem;
}
.btn-form p{
  font-size: 0.7rem;
}
.btn-form> svg{
  font-size: 0.7rem;
}
.form-container{
  overflow-y: scroll;
  margin-top: 0;
  /* padding-top: 6rem; */
  padding: 7rem 1rem 1rem 1rem;
  background-color: var(--white);
  max-width: var(--max-width);
  margin: auto;
  
}
.form-wrapper{
  /* height: 100%; */
  padding:var(--basic-padding);
  padding-top: 1em;
  padding-bottom: 1em;
  max-width: 1000px;
  margin: auto;
}
.project-section{
  display: flex;
  flex-direction: column;
  gap:1.5rem;
}
.field-wrapper{
  display: flex;
  flex-direction: column;
  /* flex-wrap: wrap; */
  justify-content: space-between;
  gap:0.6rem;
}
.field-wrapper>input{
  width: 100%;
  padding: 0.3rem;
  border:none;
  box-shadow:  var(--btn-shadow-secondary);
  font-size: 0.9rem !important;
}
.btn-form{
  border-radius: 10rem;
  display: flex;
  flex-direction: row;
  gap:0.5rem;
  align-items: center;
  justify-content: center;
}
.collaborators-wrapper,.resources-wrapper,.consultant-wrapper{
  display:flex;
  flex-direction: column;
  gap:0.5rem;
}
.collaborator-wrapper, .resource-wrapper{
  padding: 0 0.2rem 1rem 0.2em;
  display: flex;
  flex-direction: column;
  gap:0.5rem;
  box-shadow: var(--divider-btm-shadow);
}
.collaborator-wrapper>button,.resource-wrapper>button{
  margin-left: auto;
}
.address-wrapper{
  padding-top:1.5rem;
}
.address-search{
  font-size: 1rem;
  flex:1;
}

.mapboxgl-ctrl-geocoder{
  width: 100% !important;
  max-width: 100% !important;
  padding: 0 0 0 2rem;
  box-shadow:  var(--btn-shadow-secondary);
}
.mapboxgl-ctrl-geocoder--input{
  height: inherit;
  box-shadow: none !important;
}
.mapboxgl-ctrl-geocoder--icon{
  margin: auto 0;
  bottom:0;
  top:0;
}

.description-wrapper>label{
  display: flex;
  flex-direction: column;
  gap:0.3rem;
}
/*Reference seciton*/
.break-url{
  word-break: break-all;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* Number of lines to show */
  -webkit-box-orient: vertical;
}
.reference-container{
  display: flex;
  flex-direction: row;
  gap:0.5rem;
  width: 100%;
}
.reference-fields-wrapper{
  display: flex;
  flex-direction: column;
  /* gap:0.2rem; */
  flex:1;
}
.innovation-section, .typology-section{
  padding-top:1.5rem;
  display: flex;
  flex-direction: column;
  
}
.typology-section{
  gap:0.6rem;
}
.innovation-wrapper{
  display: flex;
  flex-direction: column;
  gap:0.8rem;
  padding: 0.75rem 0;
  box-shadow: var(--divider-btm-shadow);
}
.innovation-filter-wrapper, .innovation-description-wrapper{
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap:0.6rem;
}
.classifications-wrapper,.categories-wrapper{
  display: flex;
  flex-direction: column;
  gap:0.3rem;
  margin: 0 0.5rem;
  font-size: 0.9rem;
}
.form-filter{
  font-size: 0.9rem;
}
.classification-wrapper,.category-wrapper{
  display: flex;
    flex-direction: row;
    gap:0.5em;
    
}
.classification-wrapper>input{
  display: initial;
  padding:0;
  width: fit-content;
}
.related-resources{
  display: flex;
  flex-direction: column;
  gap:0.7rem;
  padding-top:1.5rem;
}
.typology-name-wrapper{
  display: flex;
  flex-direction: column;
  gap:0.75rem;
}
.image-section, .submitter-section{
  display: flex;
  flex-direction: column;
  gap:0.7rem;
  padding-top:1.5rem;
}
.submitter-wrapper{
  display: flex;
  flex-direction: column;
  gap: 0.6rem;
}
.submitter-name-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 100%;
  gap:1rem;
}
.submitter-name-wrapper>.field-wrapper{
  display: flex;
  flex-direction: row;
  flex:1;
  white-space: nowrap;
  /* gap:1rem */
}
.submitter-name-wrapper>.field-wrapper>h6{
  width: fit-content;
}
.submitter-name-wrapper>.field-wrapper>input{
  min-width: 10rem;
  flex:1
}
.after-submit-text{
  margin: 2rem auto 0 auto;
  width: fit-content;
  
}
.form-login>div{
  background-color: var(--black);
}
form {
  background-repeat: no-repeat;
  background-size: cover;
  /* background-image: url("../images/Calgary_DT_white.jpg"); */
}

label {
  display: block;
}


input[type="text"], 
textarea {
  padding: 0.3rem;
  border:none;
  box-shadow:  var(--btn-shadow-secondary);
  font-size: 0.9rem !important;
  width: 100%;
}

.btn-submit {
  margin:2rem auto 0 auto;
  color:var(--white);
  background-color: var(--black);
  display: block;
}

textarea {
  height: 10rem;
}
.upload-type-wrapper{
  display: flex;
  flex-direction: row;
  gap:0.5rem;
}
.radio-types{
  display: flex;
  flex-direction: row;
  gap:0.5rem;
}
.option-fields-wrapper{
  display: flex;
  flex-direction: column;
  gap:0.8rem;
  padding:0.5rem 0;
}
.img-source-conatiner{
  display: flex;
flex-direction: column;
gap:0.3rem;
}
.img-sources-wrapper{
font-size: 0.7rem;
display: flex;
flex-direction: column;
gap:0.5rem;
padding: 0 0.2rem;
}
.img-preview-form{
  aspect-ratio: 4/3;
  object-fit: cover;
  object-position: center center;
  width: 100%
}
.image-previews-container{
  width: fit-content;
  padding:0.5rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap:0.1rem;
  /* justify-content: center; */
}

.image-previews-container figure{
 /* width: fit-content; */
 width: 30%;
 /* margin: 0; */
}
.image-previews-container figcaption{
 /* width: fit-content; */
 width: 100%;
 /* margin: 0; */
}
.image-previews-container input[type="text"]{
 width: 100% ;
  font-size: 0.6rem !important;
}

@media (min-width: 768px) {
  
  .form-container {
    padding-top:5rem ;

  }


}

/*admin page*/
[data-amplify-authenticator]{
  position:absolute;
  left: 0;
  right: 0;
  top:0;
  bottom:0;
}
.amplify-tabs {
  display: none !important;/*to delete signin*/
}
.admin-navbar{
  position:fixed;
  box-shadow: var(--divider-btm-shadow);
  background-color: var(--light-grey);
  top: 4rem;
  width: 100%;
  height:2rem;
  padding:0.3rem 2rem;
  font-size: 0.8rem;
  display: flex;
  align-items: center;
  flex-direction: row;
  justify-content: space-between;
  gap:1rem;
  z-index: 500;
}
.admin-navbar>a{
  text-decoration: none;
  color: var(--black);
}
.admin-navbar>a:hover{
  color: var(--medium-grey);
}
.admin-nav-taps-wrapper{
  display: flex;
  gap: 1rem;
}
.admin-nav-usercontent-wrapper{
  display: flex;
  gap: 0.5rem;
 align-items: center;
}
.admin-user-email{
  font-size: 0.7rem;
  height: fit-content;
}
.btn-logout{
  font-size: 0.7rem;
  cursor: pointer;
  box-shadow: none;
  padding: 0.2rem;
}
.admin-main-wrapper{
  overflow-y: scroll;
 padding:8rem 1rem 1rem 1rem;
 margin:auto;
}
.admin-main-wrapper::-webkit-scrollbar {
  display: none;
}
.admin-container{
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  gap:1.5rem;
  flex:1;
}

@media (min-width: 768px) {
  .admin-navbar{
    top:4rem;
  }
}
/*admin project page*/
.btn-in-table{
  font-size: 0.8rem;
  margin:0.2rem;
  padding: 0.1rem;
  border: var(--medium-grey) solid 1px;

}

.long-text-box{
  /* margin: 0 0.5rem; */padding:0.3rem;
  border:1px rgb(190, 190, 190) solid;
  border-radius: 0.2rem;
}
.project-modal .modal-body{
  display: flex;
  flex-direction: column;
}
.view-project-edit-btn{
  margin-left: auto;
  font-size: 0.7rem;
  padding:0.3rem;
  margin-bottom: 0.7rem;
}
.admin-main-wrapper.with-sidebar{
  display: flex;
  flex-direction: row;
  align-items: flex-start;
}
.admin-project-sideBar{
  display: flex;
  flex-direction: column;
  gap:1rem;
}

.project-taps-wrapper a{
  color:var(--black);
  text-decoration: none;
}
.search-tools-container{
  padding:1rem;
  border-radius: 0.5rem;
  box-shadow: var(--btn-shadow-secondary);
  font-size:0.8rem;
  background-color: var(--light-grey);
  width: 12rem;
  display: flex;
  gap:1rem;
  flex-direction: column;
}
.search-bar-container{
  display:flex;
  gap:0.3rem;
  align-items: center;
}
.search-bar-container input{
  width: 80%;
  flex:1;
}
.options-wrapper{
  display: flex;
  gap:0.3rem;

}
.option-wrapper{
  display: flex;
  gap:0.2rem
}
.admin-table{
  width: 100%;
}
.admin-td-projectName{
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 10rem;
}
.admin-td-approved,.admin-td-view,.admin-td-date {
  text-align: center;
}

.admin-td-approved.notApproved {
  color: rgb(190, 138, 42);
}
.field-wrapper-row{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:1rem;
}

.line-wrapper{
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:1rem;
}
.mapped-content-wrapper{
  margin:0 0.5rem;
  display: flex;
  flex-direction: column;
  gap:0.8rem;
  font-size: 0.85rem;
}

.not-provided-text{
  color: var(--medium-grey);
}
.admin-project-view-btn{
  font-size: 0.7rem;
}
.images-wrapper{
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.image-container{
  width: 50%;
  display: flex;
  flex-direction: column;
  padding:0.3rem 0;
  /* justify-content: space-around; */
 
}
.image-container> figcaption{
  width: 100%;
  /* display: table; */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* Number of lines to show */
  -webkit-box-orient: vertical;

}
.image-container> img{
  height: fit-content;
}

.file-image-container{
  width: 50%;
}
.edit-modal textarea, .edit-modal input[type="text"]{
  width: 100%;
} 
.edit-modal h6{
  white-space: nowrap;
}
/*admin edit modal page*/
.nested-fieldname{
  width: 5rem;
}
.lines-wrapper.with-contents{
  gap:0.5rem;
  display: flex;
  flex-direction: column;
}
.filters-wrapper{
  display: flex;
  flex-direction: column;
  width: fit-content;
  align-items: flex-end;
}
.filters-wrapper .lines-wrapper{
  width: fit-content;
}
.filters-wrapper .lines-wrapper div{
  display: flex;
  gap:0.3rem;
}
.address-text-wapper{
  flex:1;
  display: flex;
  flex-direction: column;
  gap:0.3rem;
}
.edit-modal-figure{
  display: flex;
  flex-direction: column;
  gap:0.5rem;
}
/*admin add field page*/

.admin-inputs-wrapper{
  display: flex;
  padding-top:0.6rem;
  flex-direction: row;
  flex-wrap: wrap;
  gap:0.6rem;
  
}

.admin-word-wrapper{
  margin-bottom: 0;
  display: flex;
  flex-direction: row;
  gap:0.3rem;
  font-size:0.8rem;
  align-items: center;
}
.admin-field-button{
  position: relative;
}
.admin-field-change-wrapper{
  position: absolute;
  top:0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: var(--medium-grey);
  color:var(--white);
  border-radius: 0.3rem;
  opacity: 0;
}
.admin-field-change-wrapper:hover{
  opacity: 0.85;
}
.admin-field-change-wrapper.display{
transition: opacity 0.2s ease-in-out;
display: flex;
justify-content: center;
gap:0.7rem;
}
.admin-field-icon{
  height: 100%;
  cursor: pointer;
}
.admin-field-icon:hover{
 color: var(--black);
}
/*admin field change modal*/
.admin-change-field-modal form .modal-body{
  display: flex;
  flex-direction: column;
  gap:0.5rem;
}
.admin-text-line-wrapper{
 display: flex;
 flex-direction: row;
 gap:1rem;
 align-items: flex-start;
}
.admin-text-line-wrapper p{
  padding: 0.3rem 0;
  width: 5rem;
}
.admin-text-line-wrapper textarea{
  flex:1;
}
.admin-text-error-wrapper{
  flex:1;

}
.admin-text-line-wrapper input{
width: 100%;
}
.admin-error-wrapper{
  font-size: 0.7rem;
  color:rgb(205, 1, 1);
  display: flex;
  flex-direction: row;
  align-items: center;
  gap:0.3rem;
  margin-top: 0.3rem;
}
/*About page*/
.about-container{
  
 padding:1.5rem 0 4.24rem 0;
 /* justify-content: center; */
 position: relative;
 background: var(--light-grey);
 /* justify-content: center; */
}

.about-text-pharagraph{
  font-size: 0.8rem ;
}

.about-texts{
  display: flex;
  flex-direction: column;
  gap:1.8rem;
  padding: 5rem 1rem;
  background: var(--white);
  clip-path: polygon(0% 8%, 100% 0%, 100% 92%, 0 100%);
  margin:0 auto;
}


@media screen and (min-width: 768px){
  /* .map-container{
    height: calc(100% - 4rem);
  } */
  .about-container{
    padding:1.5rem 0 1.5rem 4rem;
  }
  .about-text-pharagraph{
    max-width: 1200px;
    margin: 0 auto;
  }
}